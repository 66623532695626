body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.centeredCard {
  width: 300px;

  @media (max-width: 600px) {
    width: 90%;
  }
}

table.table, table.table td, table.table th {
  border: 1px solid;
  border-collapse: collapse;
}

table.table tbody tr {
  &:hover, &:focus {
    background: #ddd;
  }
}

table.info {
  & tr.bold td {
    font-weight: bold;
    text-align: center;
  }

  & tr td:first-child {
    padding-right: 10px;
  }
}

.bp5-overlay {
  z-index: 100000 !important;

  @media (max-width: 600px) {
    & .bp5-dialog-footer {
      margin-top: auto;
    }
  }

  & .stack-modal {
    @media (max-width: 600px) {
      width: 100vw;
      height: 100vh;
      margin: 0;
    }

    & .stack-dialog {
      width: 100%;
      height: 100%;

      & .stack {
        min-height: 400px;

        @media (max-width: 600px) {
          min-height: calc(100%);
        }
      }
    }
  }
}

.optibiz-style {
  &.bp5-card {
    border-radius: 24px;
    background: #eeeeee;
  }
}

.optibiz-transparent-btn {
  &.btn.btn-primary {
    background: transparent;
    color: #000;
    border: 0;

    &:hover {
      background: none;
      color: #00f;
    }

    &.active {
      background: none;
      color: #00f;
    }
  }

  &.t-25 {
    &.btn.btn-primary {
      background: rgba(13, 110, 253, 0.25);
      color: #000;
      border: 0;
    }
  }

  &.big {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & i {
      font-size: 2em;

      & img {
        height: 1.5em;
      }
    }
  }
}

.optibiz-navbar {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 5px 10px;

  &.bottom {
    width: 300px;
    margin: 5px auto;
  }

  & .optibiz-navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;

    &.centered {
      justify-content: center;
    }

    &.spaced {
      justify-content: space-between;
    }

    & .optibiz-navbar-container-child {
      display: flex;
      align-items: center;
    }
  }

  & .optibiz-navbar-menu-btn {
    background: none;
    color: #000;
    border: 0;

    &:hover {
      background: none;
      color: #00f;
    }

    &.active {
      background: none;
      color: #00f;
    }
  }
}

.optibiz-history-item {
  width: 100%;
  display: flex;
  margin-bottom: 10px;

  & .icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #aaaaaa33;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.success {
      background: rgba(0, 255, 0, 0.31);
    }

    &.danger {
      background: rgba(255, 190, 170, 0.31);
    }
  }

  & .content {
    width: calc(100% - 65px - 15px);
  }

  &.mini {
    & .icon {
      width: 40px;
      height: 40px;
    }

    & .content {
      & .time {
        margin: 0;
        font-size: .8em;
      }

      & .bigText {
        margin: 0;
        font-weight: bold;
      }
    }
  }
}

.optibiz-line-inputs {
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}